import React from "react";
import { ConfigProvider } from "antd";
import { Locale } from "antd/lib/locale";

const withTheme = (node: JSX.Element, loc: Locale) => (
  <ConfigProvider
    locale={loc}
    prefixCls="next-antd"
    iconPrefixCls="next-antd-icon"
    theme={{
      token: {
        colorPrimary: `#4088F5`,
        borderRadius: 6,
        fontSize: 16,
        colorLink: "#4088F5",
      },
      components: {},
    }}
  >
    <ConfigProvider>{node}</ConfigProvider>
  </ConfigProvider>
);

export default withTheme;
