import styles from "./index.module.less";
import { useTranslation } from "next-i18next";
import Image from "next/image";
import { imgUrl } from "@/shared/utils";

const Index = () => {
  const { t } = useTranslation("common");
  const dataList = [
    {
      title: t("Impersonation Protection"),
      desc: t(
        "Detect and remove fake accounts, safeguarding your usernames, stage names, and brand from misuse."
      ),
      img: "/images/home/foryou/f-1.webp",
    },
    {
      title: t("Full Anonymity"),
      desc: t(
        "Submit DMCA complaints anonymously, keeping your personal info private."
      ),
      img: "/images/home/foryou/f-2.webp",
    },
    {
      title: t("Comprehensive Scanning"),
      desc: t(
        "Continuously monitor websites and platforms (including social media) to detect and prevent content leaks."
      ),
      img: "/images/home/foryou/f-3.webp",
    },
    {
      title: t("Search Engine Removal"),
      desc: t(
        "Quickly remove infringing content from search results to restore visibility and drive traffic."
      ),
      img: "/images/home/foryou/f-4.webp",
    },
    {
      title: t("Social Media Removal"),
      desc: t(
        "Instantly remove infringing content from social platforms, protecting your intellectual property."
      ),
      img: "/images/home/foryou/f-5.webp",
    },
    {
      title: t("Telegram Protection"),
      desc: t(
        "Remove infringing content on Telegram, preventing illegal sharing of your work."
      ),
      img: "/images/home/foryou/f-6.webp",
    },
  ];
  return (
    <div className={styles.container}>
      <div className={styles.newMain}>
        <div>
          <h2>{t("What Erasa.ai Do For You")}</h2>
          <h3>
            {t(
              "Protect Your Digital Identity and Content. Take Control of Your Content Today!"
            )}
          </h3>
          <Image
            src={imgUrl("/images/home/AIGrid/new-girl.webp", "mid")}
            alt="aiPower"
            width={435}
            height={465}
            loading="lazy"
            layout="responsive"
            // layout="responsive"
          ></Image>
        </div>
        <ul>
          {dataList.map((item, index) => (
            <li key={index}>
              <img src={item.img} alt={item.title} />
              <h3>{item.title}</h3>
              <p>{item.desc}</p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Index;
