import Link from "next/link";
import { Tooltip } from "antd";
import { useRouter } from "next/router";
import styles from "./index.module.less";
import { AllSupportLanguages, CurrentLanguage } from "@/shared/language";

import { CaretDownOutlined } from "@ant-design/icons";
import React from "react";

const ChangeLanguage: React.FC<{ isMobilePrpfile?: boolean }> = ({
  isMobilePrpfile = false,
}) => {
  const router = useRouter();
  const currentLocale = router.locale;

  const currentColor = (color: string) => {
    return color === currentLocale
      ? `var(--app-primary-color)`
      : `var(--app-text-color)`;
  };

  const items = () => {
    return (
      <div
        className={styles.listContainer}
        style={{ color: `var(--app-text-color)` }}
      >
        {AllSupportLanguages.map((item) => {
          return (
            <div style={{ padding: 5 }} key={item.code}>
              <span
                style={{ color: currentColor(item.code), cursor: "pointer" }}
                onClick={() =>
                  (window.location.href = `/${item?.code}${router.asPath}`)
                }
              >
                {item.name}({item.code})
              </span>
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <div className={styles.mainContainer}>
      <Tooltip title={items} color={"#FFF"} placement="top" trigger="click">
        <div
          className={styles.content}
          style={isMobilePrpfile ? { width: "100%" } : { width: "fit-content" }}
        >
          <img src="/images/common/moreLange.webp" alt="multilingual" />
          <span>{CurrentLanguage(currentLocale).name}</span>
          <CaretDownOutlined />
        </div>
      </Tooltip>
    </div>
  );
};

export default ChangeLanguage;
