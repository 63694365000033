import styles from "./index.module.less";
import { useRouter } from "next/router";

const Index = ({ content }: { content: any }) => {
  const router = useRouter();

  if (content.text == "Solution") {
    return (
      <div className={styles.Solution} onClick={(e) => e.stopPropagation()}>
        {content.dropdownItems.map((item: any, index: number) => (
          <div
            key={index}
            onClick={(e) => {
              router.push(item.path);
            }}
          >
            {item.text}
          </div>
        ))}
      </div>
    );
  } else if (content.text == "Service") {
    return (
      <div className={styles.Service} onClick={(e) => e.stopPropagation()}>
        {content.dropdownItems.map((item: any, index: number) => (
          <div
            key={index}
            onClick={(e) => {
              router.push(item.path);
            }}
          >
            <div className={styles.serviceItemHead}>
              <img
                src={
                  index == 0
                    ? "https://front-node-res-new.s3.us-west-2.amazonaws.com/prod-erasa/image/monitoring-blue.svg"
                    : "https://front-node-res-new.s3.us-west-2.amazonaws.com/prod-erasa/image/sz-blue.svg"
                }
                alt={item.text}
              />
              <span>{item.text}</span>
            </div>
            {index == 0 && (
              <ul className={styles.serviceItemList}>
                <li
                  onClick={(e) => {
                    router.push("/content-monitoring/reverse-username-search");
                  }}
                >
                  Reverse Username Search
                </li>
                <li
                  onClick={(e) => {
                    router.push("/content-monitoring/reverse-video-search");
                  }}
                >
                  Reverse Video Search
                </li>
                <li
                  onClick={(e) => {
                    router.push("/content-monitoring/reverse-photo-search");
                  }}
                >
                  Reverse Photo Search
                </li>
                <li
                  onClick={(e) => {
                    router.push("/content-monitoring/reverse-face-search");
                  }}
                >
                  Reverse Face Search
                </li>
              </ul>
            )}
          </div>
        ))}
      </div>
    );
  } else if (content.text == "Resources") {
    return (
      <div className={styles.Resources} onClick={(e) => e.stopPropagation()}>
        <div>
          <div
            onClick={(e) => {
              router.push("/blog");
            }}
          >
            Blog
          </div>
          <div
            onClick={(e) => {
              router.push("/dmca-protection-badge");
            }}
          >
            DMCA Badges
          </div>
        </div>
        <div>
          <div>Free Tools</div>
          <div
            onClick={(e) => {
              router.push("/onlyfans-restricted-words-checker");
            }}
          >
            OnlyFans Restricted Words Checker
          </div>
          <div
            onClick={(e) => {
              router.push("/onlyfans-caption-generator");
            }}
          >
            OnlyFans Caption Generator
          </div>
          <div
            onClick={(e) => {
              router.push("/compare");
            }}
          >
            Platform Comparison
          </div>
        </div>
        <div>
          <div>Community</div>
          <div
            onClick={() => {
              window.open("https://discord.gg/vmZfzh98gz", "_blank");
            }}
          >
            <div>
              <img
                src="https://front-node-res-new.s3.us-west-2.amazonaws.com/prod-erasa/image/contactUs/discord.svg"
                alt="discord"
              />
            </div>
            <div>
              <div>Discord</div>
              <div>
                Receive platform operation guides and get official tips and
                support.
              </div>
            </div>
          </div>
          <div
            onClick={() => {
              window.open("https://x.com/erasaprotect", "_blank");
            }}
          >
            <div>
              <img
                src="https://front-node-res-new.s3.us-west-2.amazonaws.com/prod-erasa/image/contactUs/x.svg"
                alt="x"
              />
            </div>
            <div>
              <div>Twitter</div>
              <div>Stay updated with the latest news.</div>
            </div>
          </div>
          <div
            onClick={() => {
              window.open("https://t.me/erasaprotect", "_blank");
            }}
          >
            <div>
              <img
                src="https://front-node-res-new.s3.us-west-2.amazonaws.com/prod-erasa/image/contactUs/tg.svg"
                alt="tg"
              />
            </div>
            <div>
              <div>Telegram</div>
              <div>Get 1-on-1 support from a professional consultant.</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default Index;
