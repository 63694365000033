import React, { useState, Suspense, useRef } from "react";
import { CommonBackContent } from "../Layouts/CommonBackContent";
import { useConfigProvider } from "@/context/ConfigProvider";
import styles from "./index.module.less";
import ProtextOrigin from "./protectOriginal/index";
import FreQuestion from "./freQuestions/index";
// import AiPower from "./aiPower/index";
import AiPower from "./aiPower/index";

const TopRight = dynamic(() => import("./TopRight/index"), {
  ssr: false,
});

import TopLeft from "./TopLeft/index";

import AiShowGrid from "./aiShowGrid";
// const AiShowGrid = dynamic(() => import("./aiShowGrid"), {
//   ssr: true,
// });
import CreaterFriend from "./CreaterFriend/index";
// const CreaterFriend = dynamic(() => import("./CreaterFriend/index"), {
//   ssr: true,
// });

const BottomFolder = dynamic(() => import("./BottomFolder"), {
  ssr: false,
});
import useScroll from "@/context/UseScrollHeight";
import { useTranslation } from "next-i18next";
const CreatorSay = dynamic(() => import("./CreatorSay"), {
  ssr: true,
});
import CreatorsWorldwide from "./CreatorsWorldwide/index";

// const CreatorsWorldwide = dynamic(() => import("./CreatorsWorldwide"), {
//   ssr: true,
// });
const RecommendedArticle = dynamic(() => import("./RecommendedArticle"), {
  ssr: true,
});
// const AddAccountModal = dynamic(
//   () => import("@/components/protectedAccount/AddAcountModal"),
//   {
//     ssr: false,
//   }
// );
import AddAccountModal from "@/components/protectedAccount/AddAcountModal";

import dynamic from "next/dynamic";

const MemoCommonBackContent = React.memo(CommonBackContent);

const HomeContent = ({ data }: { data: any }) => {
  const scrollHeight = useScroll();
  const { isMobile } = useConfigProvider();
  const { t } = useTranslation("common");
  const AccountModalRef = useRef<any>();
  const [isAddAccountModal, setIsAddAccountModal] = useState(false);
  const getAccount = (inputValue: string, selectedPlatform: string) => {
    // console.log(inputValue, selectedPlatform, AccountModalRef.current);
    setIsAddAccountModal(true);
    AccountModalRef.current.triggerStep(selectedPlatform, inputValue);
  };

  return (
    <div>
      <MemoCommonBackContent
        style={{ paddingTop: "0px", maxWidth: "1200px", margin: "0 auto" }}
      >
        <div className={styles.topMianLogo}>
          <TopLeft getAccount={getAccount}></TopLeft>
          <TopRight></TopRight>
        </div>
      </MemoCommonBackContent>
      <ProtextOrigin />
      <AiPower />
      <AiShowGrid />
      <CreaterFriend />
      <CreatorSay />
      <MemoCommonBackContent
        style={{ padding: "1rem", maxWidth: "1200px", margin: "0 auto" }}
      >
        <CreatorsWorldwide />
      </MemoCommonBackContent>
      <RecommendedArticle />
      <MemoCommonBackContent
        style={{ padding: "1rem", maxWidth: "1200px", margin: "0 auto" }}
      >
        <FreQuestion />
      </MemoCommonBackContent>
      <BottomFolder
        onOpenModal={() => setIsAddAccountModal(true)}
      ></BottomFolder>
      <AddAccountModal
        ref={AccountModalRef}
        isModalOpen={isAddAccountModal}
        handleCancel={() => setIsAddAccountModal(false)}
        isHome={true}
      ></AddAccountModal>
      {scrollHeight > 500 && isMobile ? (
        <div className={styles.bottomBtn}>
          <div onClick={() => setIsAddAccountModal(true)}>
            {t("Get Started Free")}
          </div>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default HomeContent;
