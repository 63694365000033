import styles from "./index.module.less";
import { useTranslation } from "next-i18next";
import { Dropdown, Button } from "antd";
import { useState, useMemo } from "react";
import classNames from "classnames";
const isValidUrl = (string: string) => {
  try {
    new URL(string);
    return true;
  } catch (err) {
    return false;
  }
};
const iconUrlMap1 = new Map([
  [
    "onlyfans.com",
    {
      url: "https://www.onlyfans.com/",
      icon: "/images/erasa/platforms/onlyfans.png",
    },
  ],
  [
    "tiktok.com",
    {
      url: "https://www.tiktok.com/@",
      icon: "/images/erasa/platforms/tiktok.png",
    },
  ],
  [
    "x.com",
    { url: "https://x.com/", icon: "/images/erasa/platforms/space.png" },
  ],
  [
    "instagram.com",
    {
      url: "https://www.instagram.com/",
      icon: "/images/erasa/platforms/ins.png",
    },
  ],
  [
    "patreon.com",
    {
      url: "https://www.patreon.com/",
      icon: "/images/erasa/platforms/patreon.png",
    },
  ],
  [
    "chaturbate.com",
    {
      url: "https://www.chaturbate.com/",
      icon: "/images/erasa/platforms/chaturbate.png",
    },
  ],
  [
    "fansly.com",
    {
      url: "https://www.fansly.com/",
      icon: "/images/erasa/platforms/fansly.png",
    },
  ],
  [
    "fanvue.com",
    {
      url: "https://www.fanvue.com/",
      icon: "/images/erasa/platforms/fanvue.png",
    },
  ],
  [
    "manyvids.com",
    {
      url: "https://www.manyvids.com/",
      icon: "/images/erasa/platforms/manyvids.png",
    },
  ],
  [
    "reddit.com",
    {
      url: "https://www.reddit.com/",
      icon: "/images/erasa/platforms/reddit.png",
    },
  ],
  [
    "pornhub.com",
    {
      url: "https://www.pornhub.com/",
      icon: "/images/erasa/platforms/pornhub.png",
    },
  ],
  [
    "fansone.co",
    {
      url: "https://fansone.co/",
      icon: "/images/erasa/platforms/fansone.png",
    },
  ],
]);

const iconUrlMap2 = new Map([
  ["other", { url: "Add Account", icon: "/images/erasa/platforms/other.png" }],
]);

const Index = ({
  getAccount,
}: {
  getAccount: (inputValue: string, selectedPlatform: string) => void;
}) => {
  const { t } = useTranslation("common");
  const [open, setOpen] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState("Add Account");
  const [inputValue, setInputValue] = useState("");
  const [isVaild, setIsVaild] = useState(true);
  const [vaildMessage, setVaildMessage] = useState("");
  const [step, setStep] = useState(1);

  const imgUrl = useMemo(() => {
    if (selectedPlatform == "other") {
      return iconUrlMap2.get(selectedPlatform)?.icon;
    } else {
      return iconUrlMap1.get(selectedPlatform)?.icon;
    }
  }, [selectedPlatform]);

  const handleAddAccount = () => {
    if (selectedPlatform == "Add Account") {
      setIsVaild(false);
      setVaildMessage(t("Please select a platform"));
      return;
    }
    if (!inputValue) {
      setIsVaild(false);
      setVaildMessage(t("Please enter a valid username"));
      return;
    }
    setIsVaild(true);
    getAccount(inputValue, iconUrlMap1.get(selectedPlatform)?.url);
  };

  const DropdownMenu = () => {
    return (
      <div className={styles.platforms}>
        <div>{t("Subscription Platforms")}</div>
        <ul className={styles.ul}>
          {Array.from(iconUrlMap1.keys()).map((item) => (
            <li
              key={item}
              onClick={() => {
                setSelectedPlatform(item);
                setOpen(false);
                setStep(2);
              }}
            >
              <img src={iconUrlMap1.get(item)?.icon} alt={item} />
              <div>{item}</div>
            </li>
          ))}
        </ul>
        <div>{t("Other Platforms")}</div>
        <ul className={styles.ul1}>
          {Array.from(iconUrlMap2.keys()).map((item) => (
            <li
              key={item}
              onClick={() => {
                setSelectedPlatform(item);
                setOpen(false);
                setStep(2);
              }}
            >
              <img src={iconUrlMap2.get(item)?.icon} alt={item} />
              <div>{item}</div>
            </li>
          ))}
        </ul>
      </div>
    );
  };
  const handleBlur = () => {
    if (selectedPlatform == "other") {
      return;
    }
    if (isValidUrl(inputValue)) {
      const urlObk = new URL(inputValue);
      const regex = /^(?:https?:\/\/)?(?:www\.)?([^\/]+)/;
      const match = urlObk.hostname.match(regex);
      const domain = match ? match[1] : null;
      setSelectedPlatform(domain);
      if (domain == "tiktok.com") {
        const pathname = urlObk.pathname.slice(2);
        setInputValue(pathname);
      } else {
        const pathname = urlObk.pathname.slice(1);
        setInputValue(pathname);
      }
    }
  };
  return (
    <div>
      <div
        className={classNames({
          [styles.container1]: step == 1,
          [styles.activeContainer1]: step == 2,
        })}
      >
        <div className={styles.container}>
          {step == 1 && (
            <div className={styles.step0}>
              <Dropdown
                dropdownRender={() => DropdownMenu()}
                trigger={["click"]}
                autoAdjustOverflow={false}
                open={open}
                onOpenChange={setOpen}
              >
                <div style={{ width: "100%" }}>
                  <img
                    src="/images/home/platforms.webp"
                    alt="platforms"
                    className={styles.img}
                    onClick={() => setOpen(true)}
                  />
                  <span className={styles.addAccount}>{t("Add Account")}</span>
                </div>
              </Dropdown>
            </div>
          )}
          {step == 2 && (
            <div className={styles.step2}>
              <img src={imgUrl} className={styles.img1} alt="platforms" />
              <input
                required
                type="text"
                name="text"
                placeholder={
                  selectedPlatform == "other"
                    ? "https://example.com"
                    : "e.g.username (without symbol)"
                }
                autoComplete="off"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
                className={styles.input}
                onBlur={handleBlur}
              />
            </div>
          )}
          <label
            className={classNames(styles.label, {
              [styles.label2]: step == 2,
            })}
          >
            {selectedPlatform}
          </label>
          {step == 2 && (
            <div
              className={styles.error}
              onClick={() => {
                setIsVaild(true);
                setVaildMessage("");
                setSelectedPlatform("Add Account");
                setInputValue("");
                setStep(1);
              }}
            >
              x
            </div>
          )}
          <div className={styles.btn}>
            <Button onClick={handleAddAccount}>{t("Get Started Free")}</Button>
          </div>
        </div>
      </div>
      <div style={{ height: "20px" }}>
        {vaildMessage && (
          <div
            className={styles.vaildMessage}
            style={{ color: isVaild ? "#e0e0e0" : "#ff4d4f" }}
          >
            {vaildMessage}
          </div>
        )}
      </div>
      <div className={styles.btnMobile}>
        <Button onClick={handleAddAccount}>{t("Get Started Free")}</Button>
      </div>
    </div>
  );
};

export default Index;
