import { useTranslation } from "next-i18next";

export const isProd =
  process.env.NODE_ENV === "production" &&
  !process.env.NEXT_APP_API_HOST.includes("-test.");

interface Introduce {
  titleKey: string;
  descKey: string;
  imgKey: string;
}
interface PageMetadata {
  titleKey: string;
  descriptionKey: string;
  title1Key: string;
  title2Key: string;
  titleDescKey: string;
  introduces: Introduce[];
}
// App 6
export const AppType = process.env.NEXT_APP_TENANTID;

export const isDrFans = "16" == AppType;

// name
const getAppName = () => {
  switch (AppType) {
    case "16":
      return "erasa";
    default:
      return "";
  }
};

const appTitle = `Erasa｜Remove Leaked Content Now! Stop Fakes and Protect Your Content`;
const appDesc = `OnlyFans Content leaked?   Erasa helps you quickly detect and remove leaked content, wipe out the fakes, and protect your creations and followers! Act now and keep your unique content safe!`;
export const lowerName = getAppName().replace(/ /g, "_").toLowerCase();

const IntroduceVisa = [
  {
    titleKey: "introduce_title_saot",
    descKey: "introduce_desc_saot",
    imgUrl: "/images/home/introduce/visa_saot.webp",
  },
  {
    titleKey: "introduce_title_sbsi",
    descKey: "introduce_desc_sbsi",
    imgUrl: "/images/home/introduce/visa_sbsi.webp",
  },
  {
    titleKey: "introduce_title_kyfb",
    descKey: "introduce_desc_kyfb",
    imgUrl: "/images/home/introduce/visa_kyfb.webp",
  },
  {
    titleKey: "introduce_title_fomt",
    descKey: "introduce_desc_fomt",
    imgUrl: "/images/home/introduce/visa_fomt.webp",
  },
];
const IntroduceHtsw = [
  {
    titleKey: "introduce_title_kmaw",
    descKey: "introduce_desc_kmaw",
    imgUrl: "/images/home/introduce/htsw_kmaw.webp",
  },
  {
    titleKey: "introduce_title_gci",
    descKey: "introduce_desc_gci",
    imgUrl: "/images/home/introduce/htsw_gci.webp",
  },
  {
    titleKey: "introduce_title_cdwy",
    descKey: "introduce_desc_cdwy",
    imgUrl: "/images/home/introduce/htsw_cdwy.webp",
  },
  {
    titleKey: "introduce_title_mcs",
    descKey: "introduce_desc_mcs",
    imgUrl: "/images/home/introduce/htsw_mcs.webp",
  },
];
const IntroduceHtss = [
  {
    titleKey: "introduce_title_becw",
    descKey: "introduce_desc_becw",
    imgUrl: "/images/home/introduce/htss_becw.webp",
  },
  {
    titleKey: "introduce_title_tyiw",
    descKey: "introduce_desc_tyiw",
    imgUrl: "/images/home/introduce/htss_tyiw.webp",
  },
  {
    titleKey: "introduce_title_pitl",
    descKey: "introduce_desc_pitl",
    imgUrl: "/images/home/introduce/htss_pitl.webp",
  },
  {
    titleKey: "introduce_title_slty",
    descKey: "introduce_desc_slty",
    imgUrl: "/images/home/introduce/htss_slty.webp",
  },
];
const IntroduceMyts = [
  {
    titleKey: "introduce_title_siay",
    descKey: "introduce_desc_siay",
    imgUrl: "/images/home/introduce/myts_siay.webp",
  },
  {
    titleKey: "introduce_title_esoi",
    descKey: "introduce_desc_esoi",
    imgUrl: "/images/home/introduce/myts_esoi.webp",
  },
  {
    titleKey: "introduce_title_daat",
    descKey: "introduce_desc_daat",
    imgUrl: "/images/home/introduce/myts_daat.webp",
  },
  {
    titleKey: "introduce_title_etui",
    descKey: "introduce_desc_etui",
    imgUrl: "/images/home/introduce/myts_etui.webp",
  },
];
// 转换函数
function convertToIntroduce(item: (typeof IntroduceVisa)[0]): Introduce {
  return {
    titleKey: item.titleKey,
    descKey: item.descKey,
    imgKey: item.imgUrl, // 注意这里将 imgUrl 映射到 imgKey
  };
}

const introducesVisa: Introduce[] = IntroduceVisa.map(convertToIntroduce);
const introducesHtsw: Introduce[] = IntroduceHtsw.map(convertToIntroduce);
const introducesHtss: Introduce[] = IntroduceHtss.map(convertToIntroduce);
const introducesMyts: Introduce[] = IntroduceMyts.map(convertToIntroduce);
export enum PageType {
  Normal = "normal",
  ViewInstagramStories = "view-instagram-anonymously",
  SeeWhatSomeoneLikes = "how-to-see-what-someone-likes-on-instagram-in-2024",
  SeeSomeoneActivity = "how-to-see-someone-s-activity-on-instagram",
  SeeWhatSomeoneLikes2025 = "how-to-see-what-someone-likes-on-instagram-in-2025",
  SeeSomeoneActivity2025 = "how-to-see-someone-s-activity-on-instagram-2025",
  MonitorTeenActivity = "monitor-your-teen-s-instagram-activity",
}

// 创建一个映射，将页面类型与其元数据关联起来
export const pageMetadataMap: Record<PageType, PageMetadata> = {
  [PageType.Normal]: {
    titleKey: "head_Meta_Title",
    descriptionKey: "head_Meta_Desc",
    title1Key: "Your Ultimate Instagram Activity Tracker",
    title2Key: "Know More About Who You Care About",
    titleDescKey: "TrackingTheInstagramAccountsThatMatterToYou_Desc",
    introduces: [],
  },
  [PageType.ViewInstagramStories]: {
    titleKey: "head_Meta_Title_visa",
    descriptionKey: "head_Meta_Desc_visa",
    title1Key: "landing_page_visa_title_1",
    title2Key: "landing_page_visa_title_2",
    titleDescKey: "landing_page_visa_desc",
    introduces: introducesVisa,
  },
  [PageType.SeeWhatSomeoneLikes2025]: {
    titleKey: "head_Meta_Title_htsw_2025",
    descriptionKey: "head_Meta_Desc_visa_htsw",
    title1Key: "landing_page_htsw_title_1",
    title2Key: "landing_page_htsw_title_2_2025",
    titleDescKey: "landing_page_htsw_desc_2025",
    introduces: introducesHtsw,
  },
  [PageType.SeeWhatSomeoneLikes]: {
    titleKey: "head_Meta_Title_htsw",
    descriptionKey: "head_Meta_Desc_visa_htsw",
    title1Key: "landing_page_htsw_title_1",
    title2Key: "landing_page_htsw_title_2",
    titleDescKey: "landing_page_htsw_desc",
    introduces: introducesHtsw,
  },
  [PageType.SeeSomeoneActivity]: {
    titleKey: "head_Meta_Title_htss",
    descriptionKey: "head_Meta_Desc_visa_htss",
    title1Key: "landing_page_htss_title_1",
    title2Key: "landing_page_htss_title_2",
    titleDescKey: "landing_page_htss_desc",
    introduces: introducesHtss,
  },
  [PageType.SeeSomeoneActivity2025]: {
    titleKey: "head_Meta_Title_htss_2025",
    descriptionKey: "head_Meta_Desc_visa_htss",
    title1Key: "landing_page_htss_title_1",
    title2Key: "landing_page_htss_title_2",
    titleDescKey: "landing_page_htss_desc",
    introduces: introducesHtss,
  },
  [PageType.MonitorTeenActivity]: {
    titleKey: "head_Meta_Title_myts",
    descriptionKey: "head_Meta_Desc_visa_myts",
    title1Key: "landing_page_myts_title_1",
    title2Key: "landing_page_myts_title_2",
    titleDescKey: "landing_page_myts_desc",
    introduces: introducesMyts,
  },
};
// app logo
const getAppLogo = `/images/layouts/${lowerName}-logo.png`;
// app net logo
const getAppNetLogo = `${process.env.NEXT_APP_WEB_HOST}/images/layouts/${lowerName}-logo.png`;
// app name
const getNamePath = `/images/layouts/${lowerName}.png`;
// app ico
const getAppICO = `/${lowerName}_favicon.ico`;

// const getWebHost = `https://www.dolphinradar.com`;
// const getBlogHost = `https://www.dolphinradar.com`;

export const currentHost = () => {
  if (typeof window === "undefined") {
    return "";
  }

  if (window.location.host.includes("localhost")) {
    return `localhost:3000`;
  } else if (window.location.host.includes("-test")) {
    return `www-test.erasa.ai`;
  } else {
    return `www.erasa.ai`;
  }
};

const getWebHost = () => {
  if (typeof window === "undefined") {
    return "";
  }

  if (window.location.host.includes("-test.")) {
    return `https://www-test.erasa.ai`;
  } else {
    return `https://www.erasa.ai`;
  }
};

//
const getFirebaseConfig = () => {
  if (isProd) {
    return {
      apiKey: "AIzaSyBxxNm-z2lMXuYQuBG3vU811nS4ta5xqi8",
      authDomain: `${currentHost()}/fb-auth`,
      projectId: "erasa-prod",
      storageBucket: "erasa-prod.appspot.com",
      messagingSenderId: "4258911547",
      appId: "1:4258911547:web:ba126452ed9b05c16a531e",
      measurementId: "G-DY5GP8XTSE",
    };
  } else {
    return {
      apiKey: "AIzaSyARm3ZoXb8-du6oLLKIbApwwZexJr65ZPM",
      authDomain: `${currentHost()}/fb-auth`,
      projectId: "erasa-test",
      storageBucket: "erasa-test.appspot.com",
      messagingSenderId: "331852006039",
      appId: "1:331852006039:web:c2efb276b9cd52bd355fd2",
      measurementId: "G-KPSMTLJ8LF",
    };
  }
};

interface AppSettings {
  type: number;
  name: string;
  logoUrl: string;
  logoPath: string;
  namePath: string;
  icoPath: string;
  title: string;
  headSubTitle: string;
  desc: string;
  firebaseConfig: {};
  navItems: [];
  webHost: string;
  clarityId: string;
  clarityScript: string;
}

export const AppSettings = {
  type: AppType,
  name: getAppName(),
  logoUrl: getAppNetLogo,
  logoPath: getAppLogo,
  icoPath: getAppICO,
  namePath: getNamePath,
  title: appTitle,
  desc: appDesc,
  firebaseConfig: getFirebaseConfig(),
  webHost: getWebHost(),
  clarityId: process.env.NEXT_PUBLIC_CLARITY_ID,
  clarityScript: `https://www.clarity.ms/tag/${process.env.NEXT_PUBLIC_CLARITY_ID}`,
};

export const commonImgPath = `https://res-front.pumpsoul.com/prod-erasa/image`;
