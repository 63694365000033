import axios from "@/shared/axios";
import { BaseResponse } from "@/types/web.interface";

interface TdkResponse {
  title: string;
  description: string;
  keywords: string;
}

export async function getTdkApi(pageRoute: string, locale: string) {
  const res = await axios.get<BaseResponse<TdkResponse>>(
    `/tdk?pageRoute=${encodeURIComponent(pageRoute)}`,
    {
      headers: {
        "Current-Language": locale,
      },
      baseURL: process.env.NEXT_APP_BLOG_API_HOST,
    }
  );
  return res.data;
}
