import styles from "./index.module.less";
import { useTranslation } from "react-i18next";
interface dataList {
  title: string;
  titleColor: string;
  content: string;
}

const Index = () => {
  const { t } = useTranslation();
  const dataList: dataList[] = [
    {
      title: t("9+ yrs"),
      titleColor: "#428DFF",
      content: "Copyright Protection",
    },
    {
      title: "5M+",
      titleColor: "#F847AA",
      content: "Cases Resolved",
    },
    {
      title: "96%",
      titleColor: "#428DFF",
      content: "Success Rate",
    },
    {
      title: "24/7",
      titleColor: "#F847AA",
      content: "Monitoring",
    },
  ];
  return (
    <div className={styles.container}>
      <h2>{t("Tusted by Creators Worldwide")}</h2>
      <ul className={styles.main}>
        {dataList.map((item, index) => (
          <li key={index}>
            <p
              style={{
                color: item.titleColor,
                fontFamily: "Inter-700",
                fontWeight: "bolder",
              }}
            >
              {item.title}
            </p>
            <h3>{t(item.content)}</h3>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default Index;
