import React, { useEffect, useState, useMemo } from "react";
import type { NextPage } from "next";
import type { AppProps } from "next/app";
import { Router, useRouter } from "next/router";
import NProgress from "nprogress";
import "@/styles/mixins.less";
import "@/styles/fonts.css";
import "@/styles/globals.less";
import "nprogress/nprogress.css";
import "@/layouts/index.less";
import { App as AntdApp } from "antd";
import ConfigProvider from "@/context/ConfigProvider";
import ShoppingCartProvider from "@/context/UserProvider";
import withTheme from "@/theme";
import "@/lib/hooks/common/firebaseConfig/init";
import { appWithTranslation } from "next-i18next";
import { Locale } from "antd/lib/locale";
import { CurrentLanguage } from "@/shared/language";
const Login = dynamic(() => import("@/components/Login"), {
  ssr: false,
  loading: () => <div>Loading...</div>,
});
import UserProvider from "@/context/UserProvider";
import dynamic from "next/dynamic";
// import CustomHead from '@/components/Common/CustomHead';
// import LoginResult from "@/components/LoginResult";
const LoginResult = dynamic(() => import("@/components/LoginResult"), {
  ssr: false,
  loading: () => <div>Loading...</div>,
});

// import ArticleBottomAlert from '@/components/Common/ArticleBottomAlert';
import { AppSettings } from "@/shared/app-common";
import {
  HydrationBoundary,
  QueryClient,
  QueryClientProvider,
  dehydrate,
} from "@tanstack/react-query";
import { getTdkApi } from "@/lib/service/tdk";

Router.events.on("routeChangeStart", NProgress.start);
Router.events.on("routeChangeError", NProgress.done);
Router.events.on("routeChangeComplete", NProgress.done);

require("../styles/ins-theme.less");

const CookieConsentContent = dynamic(
  () => import("@/components/Common/CookieConsent"),
  {
    ssr: false,
    loading: () => <div>Loading...</div>,
  }
);
export type NextPageWithLayout<P = {}> = NextPage<P> & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

type AppPropsWithLayout<
  P = {
    dehydratedState?: unknown;
  },
> = AppProps<P> & {
  Component: NextPageWithLayout<P>;
};
const setupRouterEvents = () => {
  Router.events.on("routeChangeStart", NProgress.start);
  Router.events.on("routeChangeError", NProgress.done);
  Router.events.on("routeChangeComplete", NProgress.done);

  return () => {
    Router.events.off("routeChangeStart", NProgress.start);
    Router.events.off("routeChangeError", NProgress.done);
    Router.events.off("routeChangeComplete", NProgress.done);
  };
};

const getJsonData = () => ({
  "@context": "https://schema.org",
  "@type": "WebSite",
  name: "Erasa",
  image: AppSettings.logoUrl,
  url: process.env.NEXT_APP_WEB_HOST,
  applicationCategory: "Erasa｜Remove Leaked Content Now! Protect Your Content",
});

// const getJsonListData = () => ({
//   "@context": "http://schema.org",
//   "@type": "BreadcrumbList",
//   itemListElement: [
//     {
//       "@type": "ListItem",
//       position: 1,
//       item: {
//         "@id": "https://www.erasa.ai",
//         name: "Home",
//       },
//     },
//     {
//       "@type": "ListItem",
//       position: 2,
//       item: {
//         "@id": "https://www.erasa.ai/dmca-protection-badge",
//         name: "DMCA",
//       },
//     },
//     {
//       "@type": "ListItem",
//       position: 3,
//       item: {
//         "@id": "https://www.erasa.ai/plan",
//         name: "PLAN",
//       },
//     },
//     {
//       "@type": "ListItem",
//       position: 4,
//       item: {
//         "@id": "https://www.erasa.ai/onlyfans-restricted-words-checker",
//         name: "Onlyfans-Restricted-Words-Checker",
//       },
//     },
//     {
//       "@type": "ListItem",
//       position: 5,
//       item: {
//         "@id": "https://www.erasa.ai/onlyfans-caption-generator",
//         name: "Onlyfans-Caption-Generator",
//       },
//     },
//     {
//       "@type": "ListItem",
//       position: 6,
//       item: {
//         "@id": "https://www.erasa.ai/blog",
//         name: "Blog",
//       },
//     },
//   ],
// });

function App({ Component, pageProps }: AppPropsWithLayout) {
  const [loc, setLoc] = useState<Locale>();
  // Use the layout defined at the page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);
  const router = useRouter();
  const queryClient = new QueryClient();
  // 5. 使用useMemo优化JSON数据
  const jsonData = useMemo(() => getJsonData(), []);
  // const jsonListData = useMemo(() => getJsonListData(), []);

  // 6. 优化副作用
  useEffect(() => {
    // 设置路由事件
    const cleanup = setupRouterEvents();
    return cleanup;
  }, []);

  useEffect(() => {
    setLoc(CurrentLanguage(router.locale).locale);
  }, [router.locale]);

  // 7. 优化source处理
  useEffect(() => {
    if (
      router.isReady &&
      !localStorage.getItem("source") &&
      router.query.source
    ) {
      localStorage.setItem("source", router.query.source as string);
    }
  }, [router.isReady, router.query.source]);

  // 8. 优化clarity脚本
  const clarityScript = useMemo(
    () => ({
      __html: `(function(c,l,a,r,i,t,y){
      c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
      t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
      y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
    })(window, document, "clarity", "script", "${AppSettings.clarityId}");`,
    }),
    []
  );

  // 1. 优化脚本加载
  useEffect(() => {
    // 延迟加载非关键脚本
    const loadNonCriticalScripts = () => {
      requestIdleCallback(() => {
        const script = document.createElement("script");
        script.src = AppSettings.clarityScript;
        script.async = true;
        script.defer = true;
        document.body.appendChild(script);
      });
    };

    // 等待首屏内容加载完成后再加载其他脚本
    if (document.readyState === "complete") {
      loadNonCriticalScripts();
    } else {
      window.addEventListener("load", loadNonCriticalScripts);
      return () => window.removeEventListener("load", loadNonCriticalScripts);
    }
  }, []);

  return withTheme(
    <AntdApp>
      <script
        async
        defer
        data-type="clarity-script"
        dangerouslySetInnerHTML={clarityScript}
      />
      <script
        defer
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(jsonData),
        }}
      />
      {/* <script
        defer
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify(jsonListData),
        }}
      /> */}
      <ConfigProvider>
        <UserProvider>
          <QueryClientProvider client={queryClient}>
            <HydrationBoundary state={pageProps?.dehydratedState}>
              <ShoppingCartProvider>
                {getLayout(<Component {...pageProps} />)}
                <React.Suspense fallback={null}>
                  <Login />
                  <LoginResult />
                  <CookieConsentContent />
                </React.Suspense>
              </ShoppingCartProvider>
            </HydrationBoundary>
          </QueryClientProvider>
        </UserProvider>
      </ConfigProvider>
    </AntdApp>,
    loc
  );
}

App.getInitialProps = async ({ ctx }: any) => {
  const queryClient = new QueryClient();

  // 预取 TDK 数据
  await queryClient.prefetchQuery({
    queryKey: ["tdk", ctx.pathname, ctx.locale],
    queryFn: async () => {
      const res = await getTdkApi(ctx.pathname, ctx.locale);
      return res.data ?? {};
    },
  });

  return {
    pageProps: {
      dehydratedState: dehydrate(queryClient),
    },
  };
};

export default appWithTranslation(App);
