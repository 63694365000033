import React, { ReactNode } from "react";
import styles from "./index.module.less";

export const CommonBackContent: React.FC<{
  children?: ReactNode;
  style?: React.CSSProperties;
}> = ({ children, style }) => {
  return (
    <CommonBaseBackContent style={style}>
      <CommonBaseContent>{children}</CommonBaseContent>
    </CommonBaseBackContent>
  );
};

export const CommonBaseBackContent: React.FC<{
  children?: ReactNode;
  style?: React.CSSProperties;
}> = ({ children, style }) => {
  return (
    <div className={styles.mainBackContent} style={style}>
      {children}
    </div>
  );
};

export const CommonBaseContent: React.FC<{
  children?: ReactNode;
  style?: React.CSSProperties;
}> = ({ children, style }) => {
  return (
    <div
      className={styles.mainContent}
      style={{
        ...style,
      }}
    >
      {children}
    </div>
  );
};
